import React from "react";
import { Header } from "./Header";

export interface HeaderProps {
  logo?: string;
  logoId?: string;
  backgroundColor?: string;
  background?: string;
  position?: "absolute" | "relative" | "static" | "fixed";
}

const HeaderContainer: React.FC<HeaderProps> = ({
  logo,
  logoId,
  backgroundColor,
  position,
  background,
}) => {
  return (
    <Header
      logo={logo}
      logoId={logoId}
      backgroundColor={backgroundColor}
      background={background}
      position={position}
    ></Header>
  );
};

export default HeaderContainer;
