import React from "react";
import * as styles from "./Header.module.css";
import { HeaderProps } from "./index";
import { Slice } from "gatsby";

export const Header: React.FC<HeaderProps> = ({
  logo,
  logoId,
  backgroundColor,
  background,
  position,
}) => {
  const logoImg = logo || "/images/lhl-logo.svg";

  return (
    <header
      id="site-header"
      className={styles.header}
      style={{ backgroundColor: backgroundColor, position: position }}
    >
      <Slice alias="alert-banner" />
      <div className={styles.headerContainer}>
        <div className={styles.headerInner}>
          <div className={styles.logo}>
            <a href="/">
              <img
                src={logoImg}
                alt="Linda Hall Library Logo"
                data-logo-id={logoId}
                width="223"
                height="80"
              />
            </a>
          </div>
          <div className={styles.headerNavWrapper}>
            <Slice
              alias="header-content"
              backgroundColor={backgroundColor}
              background={background}
            />
          </div>
        </div>
      </div>
    </header>
  );
};
